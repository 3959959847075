import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import { Link } from 'react-router-dom';
import './Home.css'; 
import MapComponent from './MapComponent';

const Home = () => {
  return (
    <div className="pt-24"> {/* Added padding-top to offset fixed navbar */}

      {/* SEO Metadata */}
      <Helmet>
        <title>Ressurected Christ Celebration Centre Ruiru</title>
        <meta name="description" content="Experience love, community, and faith together at our church. Join us for worship services, ministries, and fellowship." />
        <meta name="keywords" content="Ressurected, Christ, Celebration, Centre, church, Ruiru, ruiru, worship, fellowship, family, youth, welcoming, best " />
        <meta property="og:title" content="Ressurected Christ celebration Centre" />
        <meta property="og:description" content="Experience love, community, and faith together at our church. Join us for worship services, ministries, and fellowship." />
        <meta property="og:url" content="https://rcccruiru.com/" /> {/* Replace with your website URL */}
      </Helmet>

      {/* Hero Video */}
      <div className="relative h-80 md:h-[400px] overflow-hidden"> {/* Increased height */}
        <img
          className="object-cover w-full h-full"
          src="home.avif"
          alt="Hero"
        />

        {/* Content Overlay */}
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Welcome to Our Church
            </h1>
            <p className="text-sm md:text-base lg:text-xl mb-4">
              Experience love, community, and faith together.
            </p>
            <Link to="/giving">
              <button className="mt-4 bg-white text-teal-900 px-4 py-2 rounded-full hover:bg-teal-900 hover:text-white transition duration-300 ease-in-out">
                Give/Donate
              </button>
            </Link>
          </div>
        </div>
      </div>

      <section className="bg-teal-800 text-white py-8 md:py-16">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4">
            NICE TO MEET YOU!
          </h2>
          <p className="text-sm md:text-base lg:text-lg mb-4">
            We are overjoyed to extend a warm welcome to you as a new member of
            our church. Your presence is a blessing to us, and we believe that
            God has brought you here for a purpose.
          </p>
          <p className="text-sm md:text-base lg:text-lg mb-4">
            As you embark on this journey with us, you'll discover a community
            of believers who are committed to love, support, and uplift each
            other in faith. Our church is a place where you can grow spiritually
            and build meaningful connections that last a lifetime.
          </p>
          <p className="text-sm md:text-base lg:text-lg mb-4">
            Our doors and hearts are open to you. Whether you are seeking
            solace, fellowship, or guidance, know that you are not alone. Our
            church family is here to walk alongside you in your spiritual
            journey, offering encouragement and a helping hand whenever needed.
          </p>
          <p className="text-sm md:text-base lg:text-lg mb-4">
            Join us for our worship services, connect with our various
            ministries, and participate in the rich tapestry of activities that
            make our church a vibrant and welcoming community. We believe that
            together, we can glorify God and make a positive impact on the world
            around us.
          </p>
          <p className="text-sm md:text-base lg:text-lg">
            Once again, welcome to our church. May your time with us be filled
            with God's love, grace, and abundant blessings. If you have any
            questions or if there's anything we can assist you with, please
            don't hesitate to reach out. We look forward to growing in faith
            together with you.
          </p>
        </div>
      </section>

      {/* Section with Bible Verse */}
      <section
        className="relative bg-cover bg-center py-16 md:py-32"
        style={{ backgroundImage: "url('./bulb.jpg')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="container mx-auto text-center relative z-10 px-4">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-bold text-white mb-4">
            Where All Are Equal
          </h2>
        </div>
      </section>

      {/* Section with Service Times */}

      <section className="bg-stone-100 text-teal-800 py-8 md:py-16">
        <div className="container mx-auto px-4 md:px-0">
          <h2 className="text-xl md:text-2xl lg:text-4xl font-bold mb-6 md:mb-8 text-center">
            Service Times & Location
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            <div className="card bg-white text-teal-900 rounded-lg p-4 md:p-6 shadow-md transform transition-transform duration-300 hover:scale-105">
              <img src="card1.svg" alt="Sunday Services Illustration" className="mb-4" width="300" height="300" />
              <h3 className="text-base md:text-lg lg:text-xl font-bold mb-2">
                Sunday Services
              </h3>
              <ul className="text-xs md:text-sm lg:text-base leading-relaxed">
                <li>6:00AM - 7:00AM - Morning Devotion Service</li>
                <li>8:00AM - 9:00AM - 2nd Morning Devotion Service</li>
                <li>9:00AM - 9:40AM - Mentorship Teaching's</li>
                <li>9:40AM - 10:30AM - Bible Study</li>
                <li>10:30AM - 1:00PM - Main Service</li>
              </ul>
            </div>
            <div className="card bg-white text-teal-900 rounded-lg p-4 md:p-6 shadow-md transform transition-transform duration-300 hover:scale-105">
              <img src="card3.svg" alt="Weekday Services Illustration" className="mb-4" width="300" height="300"  />
              <h3 className="text-base md:text-lg lg:text-xl font-bold mb-2">
                Weekday Services
              </h3>
              <ul className="text-xs md:text-sm lg:text-base leading-relaxed">
                <li>Tuesday: 6:30PM - 7:30PM - Women Fellowship</li>
                <li>Wednesday: 6:30PM - 7:30PM - Cell Group Fellowship</li>
                <li>Thursday: 6:30PM - 7:30PM - Men Fellowship</li>
                <li>Friday: 6:30PM - 7:30PM - Week Service</li>
              </ul>
            </div>
            <div className="card bg-white text-teal-900 rounded-lg p-4 md:p-6 shadow-md transform transition-transform duration-300 hover:scale-105">
              <img src="map.svg" alt="Location Illustration" className="mb-4" width="300" height="300"  />
              <h3 className="text-base md:text-lg lg:text-xl font-bold mb-2">
                Location
              </h3>
              <p className="text-xs md:text-sm lg:text-base mb-2">
                Ruiru, Wataalam Road
              </p>
              <p className="text-xs md:text-sm lg:text-base">
                Moon Hunters next to Wamaria cereals
              </p>
              <p className="text-xs md:text-sm lg:text-base">
                Google Maps Pin Location(-1.150683,36.946336)
              </p>
            </div>
          </div>
          <div className="map-container mt-8"> {/* Add the map component here */}
            <MapComponent />
          </div>
        </div>
      </section>

    </div>
  );
};

export default Home;




