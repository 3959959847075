const Strategy = () => {
  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img className="object-cover w-full h-full" src="plan.jpg" alt="Strategic Plan" />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Strategic Plan
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16 px-4 space-y-16">
        {/* Land Acquisition */}
        <div className="text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
            Land Acquisition and Development
          </h2>
          <p className="text-gray-700 mt-4 leading-relaxed">
            By God's abundant grace and guidance, our foremost goal over the next five years is to acquire our own piece of land and build a permanent house of worship that reflects the enduring strength and faith of our community. This initiative is not just about constructing a building; it is about laying down a physical and spiritual foundation that will serve as a beacon of hope, unity, and inspiration for current and future generations. Owning our land will allow us to create a sacred space that is designed specifically for our unique worship needs, fostering a deeper connection with God and with each other. This house of worship will stand as a testament to our faith, a place where we can celebrate life’s joys, seek solace in times of sorrow, and grow together as a community in the presence of God.
          </p>
        </div>

        {/* Educational Institution */}
        <div className="text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
            Establishment of an Educational Institution
          </h2>
          <p className="text-gray-700 mt-4 leading-relaxed">
            Following the successful acquisition of our land, our vision expands towards establishing an educational institution—a school or college—within the next decade. This institution will serve as a cornerstone of our community, providing a Christ-centered education that nurtures both the mind and the spirit. Our goal is to create a learning environment that not only imparts knowledge but also instills strong moral values and a sense of purpose rooted in Christian teachings. By equipping students with the skills and wisdom they need to navigate the complexities of the modern world, we aim to raise up a new generation of leaders who are not only academically proficient but also deeply committed to making a positive impact on society. This institution will be a place where faith and education intersect, where students are encouraged to explore their God-given talents and use them in service to others.
          </p>
        </div>

        {/* Disciple-Making Church */}
        <div className="text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
            Disciple-Making Church
          </h2>
          <p className="text-gray-700 mt-4 leading-relaxed">
            Our ultimate vision is to cultivate a disciple-making church that embodies the principles of inclusivity, family-friendliness, and active participation. We are dedicated to creating a welcoming and nurturing environment where every member, regardless of age, background, or life experience, feels valued and has the opportunity to grow spiritually. As expressed in our church motto, "Where All Are Equal," we are committed to ensuring that each person in our congregation is treated with dignity and respect, recognizing that we are all created in the image of God and endowed with unique gifts. Our church will be a place where everyone is encouraged to contribute their talents and to engage fully in the life of the church, whether through worship, service, or ministry. We aim to foster a deep sense of belonging and community, where members support one another in their spiritual journeys and work together to live out the teachings of Christ in everyday life. Our goal is to build a vibrant, dynamic church that not only nurtures individual faith but also equips believers to be effective witnesses and disciples, making a transformative impact in the world around us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Strategy;
