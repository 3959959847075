import React from 'react';
import { useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();

  // Card details
  const cards = [
    {
      title: 'Statement of Faith',
      imageUrl: 'faith.jpg',
      link: '/statement-of-faith'
    },
    {
      title: 'Mission, Vision, and Values',
      imageUrl: 'mission.jpg',
      link: '/mission-vision-values'
    },
    {
      title: 'Leadership',
      imageUrl: 'leadership.jpg',
      link: '/leadership'
    },
    {
      title: 'Frequently Asked Questions',
      imageUrl: 'faq.jpg',
      link: '/faq'
    },
    {
      title: 'Gallery',
      imageUrl: 'gallery.jpg',
      link: '/gallery'
    },
    {
      title: 'Strategic Plan',
      imageUrl: 'plan.jpg',
      link: '/strategic-plan'
    },
  ];

  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="about.jpeg"
          alt="background"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              About Us
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-lg p-8 mt-8 mb-12">
          <h2 className="text-3xl font-bold text-center text-teal-900 mb-4">Who We Are</h2>
          <p className="text-gray-700 leading-relaxed text-center">
            We're so glad you found us! We are a community of followers of Jesus who believe that the greatest thing in the Kingdom is right relationship; first with God and then with one another (Matthew 22:36-40). We are learning together as we grow in love and obedience in serving Him here and around the world. We'd be excited to have you join us!
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          {cards.map((card, index) => (
            <div 
              key={index}
              className="relative h-80 md:h-96 bg-cover bg-center rounded-lg shadow-lg cursor-pointer border border-gray-300 transform transition-transform duration-500 hover:scale-105 animate-fadeIn"
              style={{ backgroundImage: `url(${card.imageUrl})` }}
              onClick={() => navigate(card.link)}
            >
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white rounded-lg">
                <h3 className="text-2xl md:text-3xl font-semibold text-center px-4">{card.title}</h3>
                <button className="mt-4 px-6 py-2 bg-teal-600 text-white rounded-md text-sm md:text-base hover:bg-teal-700 transition-colors duration-300">
                  Learn More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
