import React from 'react';

const Leadership = () => {
  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="leadership.jpg"
          alt="Leadership"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Leadership
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16 px-4 space-y-16">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:pr-4 mb-4 md:mb-0">
            <img
              className="object-cover w-48 h-48 md:w-48 md:h-48 rounded-lg shadow-lg"
              src="pastor.png"
              alt="Senior Pastor Francis Shikuku"
            />
          </div>
          <div className="md:w-2/3 md:pl-4 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
              Senior Pastor
            </h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Pastor Francis Shikuku Shikwe is our dedicated Senior Pastor. With a deep
              passion for spreading the gospel, he has been instrumental in
              leading our congregation towards spiritual growth and community
              engagement. His commitment to teaching and living by the word of
              God is evident in his every action.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:pr-4 mb-4 md:mb-0">
            <img
              className="object-cover w-48 h-48 md:w-48 md:h-48 rounded-lg shadow-lg"
              src="mum3.png"
              alt="Margaret Njeri Shikuku"
            />
          </div>
          <div className="md:w-2/3 md:pl-4 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
              Pastor's Wife
            </h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Margaret Njeri Shikuku is the loving wife of Pastor Francis
              Shikuku and a pillar of support in our church community. She
              shares her husband's passion for ministry and is deeply involved
              in various church activities, especially those aimed at
              empowering women and supporting families. Her warmth and wisdom
              are a source of inspiration to many.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:pr-4 mb-4 md:mb-0">
            <img
              className="object-cover w-48 h-48 md:w-48 md:h-48 rounded-lg shadow-lg"
              src="chair2.png"
              alt="Chairman Erick Musebe"
            />
          </div>
          <div className="md:w-2/3 md:pl-4 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
              Chairman
            </h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Erick Musebe serves as the Chairman of our church board. His
              leadership and vision have been crucial in steering our church
              towards fulfilling its mission. Erick's strategic thinking and
              dedication to the church's growth are invaluable assets to our
              community.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:pr-4 mb-4 md:mb-0">
            <img
              className="object-cover w-48 h-48 md:w-48 md:h-48 rounded-lg shadow-lg"
              src="sec.jpg"
              alt="Secretary Casty Wanja"
            />
          </div>
          <div className="md:w-2/3 md:pl-4 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
              Secretary
            </h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Casty Wanja, our Secretary, is the organizational backbone of our
              church. Her meticulous attention to detail and dedication to
              service ensure that church operations run smoothly. Casty's
              welcoming nature and administrative skills make her an
              indispensable member of our leadership team.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:pr-4 mb-4 md:mb-0">
            <img
              className="object-cover w-48 h-48 md:w-48 md:h-48 rounded-lg shadow-lg"
              src="treas2.png"
              alt="Treasurer Maurice"
            />
          </div>
          <div className="md:w-2/3 md:pl-4 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">
              Treasurer
            </h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Maurice Khamala, our Treasurer, is responsible for managing the
              church's finances with integrity and transparency. His financial
              acumen ensures that our church's resources are utilized
              effectively to support our mission and vision. Maurice's
              commitment to stewardship is a cornerstone of our community's
              trust and prosperity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leadership;