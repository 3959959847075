import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Bible = () => {
  const [book, setBook] = useState('');
  const [chapter, setChapter] = useState('');
  const [verse, setVerse] = useState('');
  const [verseText, setVerseText] = useState('');

  const clearVerse = () => {
    setVerseText('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://bible-api.com/${book}+${chapter}:${verse}?translation=web`);
        setVerseText(response.data.text);
      } catch (error) {
        console.error(error);
        setVerseText('No verse found.');
      }
    };
    if (book && chapter && verse) {
      fetchData();
    }
  }, [book, chapter, verse]);

  return (
    <div className="pt-24 bg-gray-100 min-h-screen flex flex-col">
      {/* Hero Section */}
<div className="relative mb-8">
  <div className="w-full h-64 md:h-52 overflow-hidden">
    <img
      src="bible.jpg"
      alt="Hero"
      className="w-full h-full object-cover bg-center"
    />
  </div>
  <div className="absolute inset-0 bg-black opacity-50"></div>
  <div className="absolute inset-0 flex items-center justify-center text-white text-center">
    <div>
      <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold mb-4">Bible</h1>
      <p className="text-lg md:text-xl">
        Explore the Scriptures
      </p>
    </div>
  </div>
</div>

      {/* Featured Verse */}
      <div className="container mx-auto my-8 mb-8">
        <h2 className="text-3xl font-bold mb-4 text-teal-900 text-center">2 Timothy 3:16</h2>
        <p className="text-lg italic border-l-4 border-teal-900 pl-4">
          "All scripture is given by inspiration of God, and is profitable for doctrine, for reproof, for correction, for instruction in righteousness."
        </p>
      </div>

      {/* Input Fields for Book, Chapter, and Verse */}
      <div className="container mx-auto flex flex-col md:flex-row md:space-x-4 mb-4">
        <input
          type="text"
          placeholder="Book (Psalm)"
          value={book}
          onChange={(e) => setBook(e.target.value.toLowerCase())}
          className="p-2 mb-2 md:w-1/4 border border-gray-300 rounded"
        />
        <input
          type="text"
          placeholder="Chapter (23)"
          value={chapter}
          onChange={(e) => setChapter(e.target.value)}
          className="p-2 mb-2 md:w-1/4 border border-gray-300 rounded"
        />
        <input
          type="text"
          placeholder="Verse (1-6)"
          value={verse}
          onChange={(e) => setVerse(e.target.value)}
          className="p-2 mb-2 md:w-1/4 border border-gray-300 rounded"
        />
        <button
          onClick={clearVerse}
          className="bg-teal-900 text-white px-4 py-2 rounded md:w-1/4 hover:bg-teal-800"
        >
          Clear Verse
        </button>
      </div>

      {/* Display Verse with Additional Styling */}
      <div className="container mx-auto mb-4">
        {verseText && (
          <p className="text-lg border-l-4 border-teal-900 pl-4 italic">
            {verseText}
          </p>
        )}
      </div>
    </div>
  );
};

export default Bible;