import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import Home from './Home';
import Footer from './Footer';
import Events from './Events';
import Bible from './Bible';
import Connect from './Connect'
// import SermonsPage from './SermonsPage';
import About from './About';
import Sermons from './Sermons';
import Giving from './Giving';
import Admin from './Admin';
import MapComponent from './MapComponent'; // Import the MapComponent
import StatementOfFaith from './StatementOfFaith'; // Import the Statement of Faith component
import MissionVisionValues from './MissionVisionValues'; // Import the Mission, Vision, and Values component
import Leadership from './Leadership'; // Import the Leadership component
import FAQ from './FAQ';
import Gallery from './Gallery'; 
import Strategy from './Strategy';


const App = () => {
  return (
    <Router>
        <NavigationBar />

        <div className='pages'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/events" element={<Events />} />
            <Route path="/sermons" element={<Sermons />} />
            <Route path="/bible" element={<Bible />} />
            <Route path="/giving" element={<Giving />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/map" element={<MapComponent />} /> {/* Add a new route for the map */}
            <Route path="/statement-of-faith" element={<StatementOfFaith />} /> {/* Add new route */}
            <Route path="/mission-vision-values" element={<MissionVisionValues />} /> {/* Add new route */}
            <Route path="/leadership" element={<Leadership />} /> {/* Add new route */}
            <Route path="/faq" element={<FAQ />} /> 
            <Route path='/gallery' element={<Gallery />} /> {/* Add new route */}
            <Route path='strategic-plan' element={<Strategy />} />
            {/* Add more routes for other pages */}
          </Routes>
        </div>
        <Footer />
    </Router>
  );
};

export default App;
