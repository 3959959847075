import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // 3 per row, 3 rows per page

  const scheduleNotification = useCallback((title, date) => {
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          const options = {
            body: `Upcoming event: ${title}`,
            icon: 'path/to/your/icon.png',
          };

          const notification = new Notification('Church Event Reminder', options);

          notification.onclick = () => {
            alert(`Event: ${title}\nDate: ${format(date, 'yyyy-MM-dd HH:mm')}`);
          };
        }
      });
    }
  }, []);

  const scheduleNotifications = useCallback((events) => {
    events.forEach(event => {
      const eventDate = new Date(event.date);
      const currentDate = new Date();

      if (eventDate > currentDate && eventDate - currentDate < 24 * 60 * 60 * 1000) {
        scheduleNotification(event.title, eventDate);
      }
    });
  }, [scheduleNotification]);

  const fetchEvents = useCallback(() => {
    fetch('https://churchserver.onrender.com/events')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setEvents(data);
          scheduleNotifications(data);
        } else {
          console.error('Fetched data is not an array:', data);
          setEvents([]);
        }
      })
      .catch(error => {
        console.error('Error fetching events:', error);
        setEvents([]);
      });
  }, [scheduleNotifications]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const renderEventContent = useCallback((eventInfo) => (
    <>
      <b>{eventInfo.event.title}</b>
      <p>{format(eventInfo.event.start, 'MMMM dd, yyyy')}</p>
    </>
  ), []);

  // Pagination Logic
  const indexOfLastEvent = currentPage * itemsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - itemsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(events.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="pt-24 bg-gray-100 min-h-screen flex flex-col">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="sunflower.webp"
          alt="Hero"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Upcoming Events
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 md:p-8 mt-8 flex-grow">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {currentEvents.length > 0 ? (
            currentEvents.map(event => (
              <div key={event.id} className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                <img
                  src={event.image}
                  alt={event.title}
                  className="mb-2 md:mb-4 w-full h-32 md:h-40 object-cover rounded-md"
                />
                <h3 className="text-base md:text-lg lg:text-xl font-bold mb-1 md:mb-2 text-teal-900">
                  {event.title}
                </h3>
                <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1 md:mb-2">
                  {format(new Date(event.date), 'MMMM dd, yyyy')}
                </p>
                <p className="text-xs md:text-sm lg:text-base text-gray-800">{event.description}</p>
              </div>
            ))
          ) : (
            <p className="col-span-full text-center text-gray-600">No events to display</p>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="mt-8 flex justify-center">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="bg-teal-900 text-white p-2 rounded mr-2 "
          >
            Previous
          </button>
          <span className="text-teal-900 p-2">{currentPage} of {totalPages}</span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-teal-900 text-white p-2 rounded "
          >
            Next
          </button>
        </div>

        <div className="mt-8 md:mt-12 mb-8">
          <h3 className="text-lg md:text-xl lg:text-2xl font-bold mb-2 md:mb-4 text-center text-teal-900">Event Calendar</h3>
          <div className="bg-white p-4 md:p-8 rounded-md shadow-md overflow-x-auto">
            <div className="calendar-container">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventContent={renderEventContent}
                height="auto"
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                contentHeight="auto"
                aspectRatio={1.5}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

