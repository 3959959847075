import React from 'react';

const MissionVisionValues = () => {
  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="mission.jpg"
          alt="Mission Vision Values"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Vision, Mission and Core Values
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16 px-4 space-y-16">
        <div className="flex flex-col md:flex-row-reverse items-center">
          <div className="md:w-1/2 md:pl-8 mb-8 md:mb-0">
            <img
              className="object-cover w-full h-64 md:h-80 rounded-lg shadow-lg"
              src="vision.jpg"
              alt="Vision"
            />
          </div>
          <div className="md:w-1/2 md:pr-8 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">Vision</h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              To create an all-inclusive church through praying, teaching, and modeling faith.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <img
              className="object-cover w-full h-64 md:h-80 rounded-lg shadow-lg"
              src="mission1.jpg"
              alt="Mission"
            />
          </div>
          <div className="md:w-1/2 md:pl-8 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">Mission</h2>
            <p className="text-gray-700 mt-4 leading-relaxed">
              To be a Christ disciple making church and family of wholly empowered believers in Christ
              (spiritual, physical, mental, and economical well-being).
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row-reverse items-center">
          <div className="md:w-1/2 md:pl-8 mb-8 md:mb-0">
            <img
              className="object-cover w-full h-64 md:h-80 rounded-lg shadow-lg"
              src="values.webp"
              alt="Core Values"
            />
          </div>
          <div className="md:w-1/2 md:pr-8 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">Core Values</h2>
            <ul className="text-gray-700 mt-4 leading-relaxed list-disc list-inside">
              <li>Prayer</li>
              <li>Faith</li>
              <li>Stewardship</li>
              <li>Integrity</li>
              <li>Compassion</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVisionValues;