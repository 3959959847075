import React, { useState, useEffect } from "react";

const Admin = () => {
  const [eventForm, setEventForm] = useState({
    id: null,
    title: "",
    date: "",
    description: "",
    image: null,
  });
  const [sermonForm, setSermonForm] = useState({
    id: null,
    title: "",
    description: "",
    image: null,
    date: "",
  });
  const [galleryForm, setGalleryForm] = useState({
    id: null,
    description: "",
    image: null,
  });
  const [gallery, setGallery] = useState([]);
  const [events, setEvents] = useState([]);
  const [sermons, setSermons] = useState([]);
  const [messages, setMessages] = useState([]); // State for messages
  const [expandedMessageId, setExpandedMessageId] = useState(null);

  const [currentEventPage, setCurrentEventPage] = useState(1);
  const [currentSermonPage, setCurrentSermonPage] = useState(1);
  const [currentMessagePage, setCurrentMessagePage] = useState(1);
  const [currentGalleryPage, setCurrentGalleryPage] = useState(1);


  const galleryItemsPerPage = 6;
  const eventsPerPage = 6;
  const sermonsPerPage = 6;
  const messagesPerPage = 4;

  const fetchEvents = async () => {
    try {
      const response = await fetch("https://churchserver.onrender.com/events");
      if (response.ok) {
        const events = await response.json();
        setEvents(events);
      } else {
        console.error("Error fetching events");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchSermons = async () => {
    try {
      const response = await fetch("https://churchserver.onrender.com/sermons");
      if (response.ok) {
        const sermons = await response.json();
        setSermons(sermons);
      } else {
        console.error("Error fetching sermons");
      }
    } catch (error) {
      console.error("Error fetching sermons:", error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch("https://churchserver.onrender.com/connect");
      if (response.ok) {
        const messages = await response.json();
        setMessages(messages);
      } else {
        console.error("Error fetching messages");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  const fetchGallery = async () => {
    try {
      const response = await fetch("https://churchserver.onrender.com/gallery");
      if (response.ok) {
        const galleryItems = await response.json();
        setGallery(galleryItems);
      } else {
        console.error("Error fetching gallery items");
      }
    } catch (error) {
      console.error("Error fetching gallery items:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchSermons();
    fetchMessages();
    fetchGallery(); // Fetch messages on component mount
  }, []);

  const handleReadMore = (messageId) => {
    setExpandedMessageId(messageId === expandedMessageId ? null : messageId);
  };

  const handleEventChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setEventForm({ ...eventForm, image: files[0] });
    } else {
      setEventForm({ ...eventForm, [name]: value });
    }
  };

  const handleSermonChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setSermonForm({ ...sermonForm, image: files[0] });
    } else if (name === "date") {
      setSermonForm({ ...sermonForm, date: value });
    } else {
      setSermonForm({ ...sermonForm, [name]: value });
    }
  };
  const handleGalleryChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setGalleryForm({ ...galleryForm, image: files[0] });
    } else {
      setGalleryForm({ ...galleryForm, [name]: value });
    }
  };

  const handleEventEdit = (event) => {
    setEventForm({
      id: event.id,
      title: event.title,
      date: event.date,
      description: event.description,
      image: null,
    });
  };

  const handleEventDelete = async (eventId) => {
    try {
      const response = await fetch(`https://churchserver.onrender.com/events/${eventId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setEvents(events.filter((event) => event.id !== eventId));
        alert("Event deleted successfully!");
      } else {
        alert("Error deleting event!");
      }
    } catch (error) {
      alert("Error deleting event!");
      console.error("Error:", error);
    }
  };

  const handleSermonEdit = (sermon) => {
    setSermonForm({
      id: sermon.id,
      title: sermon.title,
      description: sermon.description,
      image: null,
      date: sermon.date,
    });
  };

  const handleSermonDelete = async (sermonId) => {
    try {
      const response = await fetch(
        `https://churchserver.onrender.com/sermons/${sermonId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setSermons(sermons.filter((sermon) => sermon.id !== sermonId));
        alert("Sermon deleted successfully!");
      } else {
        alert("Error deleting sermon!");
      }
    } catch (error) {
      alert("Error deleting sermon!");
      console.error("Error:", error);
    }
  };
  const handleGalleryEdit = (galleryItem) => {
    setGalleryForm({
      id: galleryItem.id,
      description: galleryItem.description,
      image: null,
    });
  };
  const handleGalleryDelete = async (galleryId) => {
    try {
      const response = await fetch(`https://churchserver.onrender.com/gallery/${galleryId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setGallery(gallery.filter((item) => item.id !== galleryId));
        alert("Gallery item deleted successfully!");
      } else {
        alert("Error deleting gallery item!");
      }
    } catch (error) {
      alert("Error deleting gallery item!");
      console.error("Error:", error);
    }
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", eventForm.title);
    formData.append("date", eventForm.date);
    formData.append("description", eventForm.description);
    if (eventForm.image) {
      formData.append("image", eventForm.image);
    }

    try {
      let response;
      if (eventForm.id) {
        response = await fetch(`https://churchserver.onrender.com/events/${eventForm.id}`, {
          method: "PUT",
          body: formData,
        });
      } else {
        response = await fetch("https://churchserver.onrender.com/events", {
          method: "POST",
          body: formData,
        });
      }

      if (response.ok) {
        const event = await response.json();
        if (eventForm.id) {
          setEvents(events.map((e) => (e.id === event.id ? event : e)));
          alert("Event updated successfully!");
        } else {
          setEvents([...events, event]);
          alert("Event added successfully!");
        }
        setEventForm({
          id: null,
          title: "",
          date: "",
          description: "",
          image: null,
        });
      } else {
        alert("Error adding/updating event!");
      }
    } catch (error) {
      alert("Error adding/updating event!");
      console.error("Error:", error);
    }
  };

  const handleSermonSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", sermonForm.title);
    formData.append("description", sermonForm.description);
    if (sermonForm.image) {
      formData.append("image", sermonForm.image);
    }
    formData.append("date", sermonForm.date);

    try {
      let response;
      if (sermonForm.id) {
        response = await fetch(
          `https://churchserver.onrender.com/sermons/${sermonForm.id}`,
          {
            method: "PUT",
            body: formData,
          }
        );
      } else {
        response = await fetch("https://churchserver.onrender.com/sermons", {
          method: "POST",
          body: formData,
        });
      }

      if (response.ok) {
        const sermon = await response.json();
        if (sermonForm.id) {
          setSermons(sermons.map((s) => (s.id === sermon.id ? sermon : s)));
          alert("Sermon updated successfully!");
        } else {
          setSermons([...sermons, sermon]);
          alert("Sermon added successfully!");
        }
        setSermonForm({
          id: null,
          title: "",
          description: "",
          image: null,
          date: "",
        });
      } else {
        alert("Error adding/updating sermon!");
      }
    } catch (error) {
      alert("Error adding/updating sermon!");
      console.error("Error:", error);
    }
  };

  const handleGallerySubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("description", galleryForm.description);
    if (galleryForm.image) {
      formData.append("image", galleryForm.image);
    }

    try {
      let response;
      if (galleryForm.id) {
        response = await fetch(`https://churchserver.onrender.com/gallery/${galleryForm.id}`, {
          method: "PUT",
          body: formData,
        });
      } else {
        response = await fetch("https://churchserver.onrender.com/gallery", {
          method: "POST",
          body: formData,
        });
      }

      if (response.ok) {
        const galleryItem = await response.json();
        if (galleryForm.id) {
          setGallery(gallery.map((item) => (item.id === galleryItem.id ? galleryItem : item)));
          alert("Gallery item updated successfully!");
        } else {
          setGallery([...gallery, galleryItem]);
          alert("Gallery item added successfully!");
        }
        setGalleryForm({
          id: null,
          description: "",
          image: null,
        });
      } else {
        alert("Error adding/updating gallery item!");
      }
    } catch (error) {
      alert("Error adding/updating gallery item!");
      console.error("Error:", error);
    }
  };
  const handleMessageDelete = async (messageId) => {
    try {
      const response = await fetch(
        `https://churchserver.onrender.com/connect/${messageId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setMessages(messages.filter((message) => message.id !== messageId));
        alert("Message deleted successfully!");
      } else {
        alert("Error deleting message!");
      }
    } catch (error) {
      alert("Error deleting message!");
      console.error("Error:", error);
    }
  };

  // Pagination handlers
  const handleEventPageChange = (page) => {
    setCurrentEventPage(page);
  };

  const handleSermonPageChange = (page) => {
    setCurrentSermonPage(page);
  };

  const handleMessagePageChange = (page) => {
    setCurrentMessagePage(page);
  };
  const handleGalleryPageChange = (page) => {
    setCurrentGalleryPage(page);
  };

  // Calculate the indexes for current page items
  const indexOfLastEvent = currentEventPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const indexOfLastSermon = currentSermonPage * sermonsPerPage;
  const indexOfFirstSermon = indexOfLastSermon - sermonsPerPage;
  const currentSermons = sermons.slice(indexOfFirstSermon, indexOfLastSermon);

  const indexOfLastMessage = currentMessagePage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(
    indexOfFirstMessage,
    indexOfLastMessage
  );

  const indexOfLastGalleryItem = currentGalleryPage * galleryItemsPerPage;
  const indexOfFirstGalleryItem = indexOfLastGalleryItem - galleryItemsPerPage;
  const currentGalleryItems = gallery.slice(indexOfFirstGalleryItem, indexOfLastGalleryItem);

  return (
    <>
      <div className="container mx-auto p-6 m-4  mt-20">
        <h1 className="text-3xl font-bold text-teal-900 mb-4">Admin Page</h1>

        {/* Upcoming Events */}
        <div className="mb-8">
        <h2 className="text-2xl font-bold text-teal-900 mb-4">
          Upcoming Events
        </h2>
        {currentEvents.length > 0 ? (
          <>
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {currentEvents.map((event) => (
                <li
                  key={event.id}
                  className="bg-white p-4 rounded-lg shadow-md mb-4 w-full sm:w-auto"
                >
                  <h3 className="text-xl font-bold text-teal-900">
                    {event.title}
                  </h3>
                  <p>Date: {event.date}</p>
                  <p>Description: {event.description}</p>
                  {event.image && (
                    <img
                      src={event.image}
                      alt={event.title}
                      className="w-full h-40 object-cover"
                    />
                  )}
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-teal-900 text-white p-2 rounded mr-2"
                      onClick={() => handleEventEdit(event)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-600 text-white p-2 rounded"
                      onClick={() => handleEventDelete(event.id)}
                    >
                      Delete
                    </button>
                  </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                {Array.from(
                  { length: Math.ceil(events.length / eventsPerPage) },
                  (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handleEventPageChange(index + 1)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentEventPage === index + 1
                          ? "bg-teal-900 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            </>
          ) : (
            <p>No upcoming events found.</p>
          )}
        </div>

        {/* Upcoming Events Form */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-teal-900 mb-2">
            Add Upcoming Event
          </h2>
          <form
            onSubmit={handleEventSubmit}
            className="bg-white p-4 rounded-lg shadow-md max-w-md"
          >
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Event Title
              </label>
              <input
                type="text"
                name="title"
                value={eventForm.title}
                onChange={handleEventChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Event Date
              </label>
              <input
                type="datetime-local"
                name="date"
                value={eventForm.date}
                onChange={handleEventChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Event Description
              </label>
              <textarea
                name="description"
                value={eventForm.description}
                onChange={handleEventChange}
                className="w-full p-2 border rounded"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">Event Image</label>
              <input type="file" id="event-image" name="image" onChange={handleEventChange} className="hidden" required />
              <label htmlFor="event-image" className="bg-teal-900 text-white p-2 rounded cursor-pointer">Choose File</label>
              {eventForm.image && <span className="ml-2">{eventForm.image.name}</span>}
            </div>
            <button
              type="submit"
              className="bg-teal-900 text-white p-2 rounded"
            >
              {eventForm.id ? "Update Event" : "Add Event"}
            </button>
          </form>
        </div>

        {/* Sermons */}
        <div className="mb-8">
        <h2 className="text-2xl font-bold text-teal-900 mb-4">Sermons</h2>
        {currentSermons.length > 0 ? (
          <>
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {currentSermons.map((sermon) => (
                <li
                  key={sermon.id}
                  className="bg-white p-4 rounded-lg shadow-md mb-4 w-full sm:w-auto"
                >
                  <h3 className="text-xl font-bold text-teal-900">
                    {sermon.title}
                  </h3>
                  <p>Date: {sermon.date}</p>
                  <p>Description: {sermon.description}</p>
                  {sermon.image && (
                    <img
                      src={sermon.image}
                      alt={sermon.title}
                      className="w-full h-40 object-cover"
                    />
                  )}
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-teal-900 text-white p-2 rounded mr-2"
                      onClick={() => handleSermonEdit(sermon)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-600 text-white p-2 rounded"
                      onClick={() => handleSermonDelete(sermon.id)}
                    >
                      Delete
                    </button>
                  </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                {Array.from(
                  { length: Math.ceil(sermons.length / sermonsPerPage) },
                  (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handleSermonPageChange(index + 1)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentSermonPage === index + 1
                          ? "bg-teal-900 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            </>
          ) : (
            <p>No sermons found.</p>
          )}
        </div>

        {/* Sermon Form */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-teal-900 mb-2">Add Sermon</h2>
          <form
            onSubmit={handleSermonSubmit}
            className="bg-white p-4 rounded-lg shadow-md max-w-md"
          >
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Sermon Title
              </label>
              <input
                type="text"
                name="title"
                value={sermonForm.title}
                onChange={handleSermonChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Sermon Date
              </label>
              <input
                type="datetime-local"
                name="date"
                value={sermonForm.date}
                onChange={handleSermonChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Sermon Description
              </label>
              <textarea
                name="description"
                value={sermonForm.description}
                onChange={handleSermonChange}
                className="w-full p-2 border rounded"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">Sermon Image</label>
              <input type="file" id="sermon-image" name="image" onChange={handleSermonChange} className="hidden" required />
              <label htmlFor="sermon-image" className="bg-teal-900 text-white p-2 rounded cursor-pointer">Choose File</label>
              {sermonForm.image && <span className="ml-2">{sermonForm.image.name}</span>}
            </div>
            <button
              type="submit"
              className="bg-teal-900 text-white p-2 rounded"
            >
              {sermonForm.id ? "Update Sermon" : "Add Sermon"}
            </button>
          </form>
        </div>

        {/* Messages */}

        <div className="mb-8">
          <h2 className="text-2xl font-bold text-teal-900 mb-4">Messages</h2>
          {currentMessages.length > 0 ? (
            <>
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {currentMessages.map((message) => (
                  <li
                    key={message.id}
                    className="bg-white p-4 rounded-lg shadow-md mb-4 w-full"
                  >
                    <h3 className="text-xl font-bold text-teal-900 truncate">
                      {message.first_name} {message.last_name}
                    </h3>
                    <p className="truncate">Email: {message.email}</p>
                    <p className="truncate">Phone: {message.phone}</p>
                    <p
                      className={`${
                        expandedMessageId === message.id ? "" : "line-clamp-3"
                      } mb-2`}
                    >
                      Message: {message.message}
                    </p>
                    {message.message.length > 150 && (
                      <button
                        className="text-teal-600 hover:text-teal-800"
                        onClick={() => handleReadMore(message.id)}
                      >
                        {expandedMessageId === message.id
                          ? "Read Less"
                          : "Read More"}
                      </button>
                    )}
                    <div className="flex justify-end mt-4">
                      <button
                        className="bg-red-600 text-white p-2 rounded"
                        onClick={() => handleMessageDelete(message.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                {Array.from(
                  { length: Math.ceil(messages.length / messagesPerPage) },
                  (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handleMessagePageChange(index + 1)}
                      className={`mx-1 px-3 py-1 rounded ${
                        currentMessagePage === index + 1
                          ? "bg-teal-900 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            </>
          ) : (
            <p>No messages found.</p>
          )}
        </div>
        {/* Gallery */}
        <div className="mb-8">
        <h2 className="text-2xl font-bold text-teal-900 mb-4">Gallery</h2>
        {currentGalleryItems.length > 0 ? (
          <>
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {currentGalleryItems.map((item) => (
                <li
                  key={item.id}
                  className="bg-white p-4 rounded-lg shadow-md mb-4 w-full"
                >
                  {item.image && (
                    <img
                      src={item.image}
                      alt={item.description}
                      className="w-full h-40 object-cover"
                    />
                  )}
                  <p className="mt-2">{item.description}</p>
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-teal-900 text-white p-2 rounded mr-2"
                      onClick={() => handleGalleryEdit(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-600 text-white p-2 rounded"
                      onClick={() => handleGalleryDelete(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-4">
              {Array.from(
                { length: Math.ceil(gallery.length / galleryItemsPerPage) },
                (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handleGalleryPageChange(index + 1)}
                    className={`mx-1 px-3 py-1 rounded ${
                      currentGalleryPage === index + 1
                        ? "bg-teal-900 text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </>
        ) : (
          <p>No gallery items found.</p>
        )}
      </div>


        {/* Gallery Form */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-teal-900 mb-2">Add Gallery Item</h2>
          <form
            onSubmit={handleGallerySubmit}
            className="bg-white p-4 rounded-lg shadow-md max-w-md"
          >
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">
                Description
              </label>
              <textarea
                name="description"
                value={galleryForm.description}
                onChange={handleGalleryChange}
                className="w-full p-2 border rounded"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-teal-900 font-bold mb-2">Gallery Image</label>
              <input type="file" id="gallery-image" name="image" onChange={handleGalleryChange} className="hidden" required />
              <label htmlFor="gallery-image" className="bg-teal-900 text-white p-2 rounded cursor-pointer">Choose File</label>
              {galleryForm.image && <span className="ml-2">{galleryForm.image.name}</span>}
            </div>
            <button
              type="submit"
              className="bg-teal-900 text-white p-2 rounded"
            >
              {galleryForm.id ? "Update Gallery Item" : "Add Gallery Item"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Admin;
