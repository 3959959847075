import React from 'react';

const StatementOfFaith = () => {
  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="faith.jpg"
          alt="Statement Of Faith"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Statement Of Faith
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-16 px-4 space-y-16">
        <div className="text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-teal-800">Purpose Statement</h2>
          <p className="text-gray-700 text-base mt-4 leading-relaxed">
            The purpose of RCCC church is to Glorify God by making disciples as prescribed in God's 
            word through worship, teaching, fellowship and evenagelism.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <img
              className="object-cover w-full h-64 md:h-80 rounded-lg shadow-lg"
              src="disciple.webp"
              alt="Disciple"
            />
          </div>
          <div className="md:w-1/2 md:pl-8 text-center md:text-left">
            <h2 className="text-2xl md:text-3xl font-bold text-teal-800">Definition of a Disciple</h2>
            <p className="text-gray-700 text-base mt-4 leading-relaxed">
              A Disciple is one who through faith has accepted the Lordship of Christ.He/She Increasingly and intentionally conforms to Christ likeness through
              a relentless pursuit of study and meditation of the word, committed to finishing the great commission.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatementOfFaith;