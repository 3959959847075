import React, { useEffect, useState } from "react";

export default function Sermons() {
  const [sermons, setSermons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // 3 per row, 3 rows per page

  useEffect(() => {
    // Fetch sermons from the backend
    fetch("https://churchserver.onrender.com/sermons")
      .then((response) => response.json())
      .then((data) => setSermons(data))
      .catch((error) => {
        console.error("There was an error fetching the sermons!", error);
      });
  }, []);

  // Pagination Logic
  const indexOfLastSermon = currentPage * itemsPerPage;
  const indexOfFirstSermon = indexOfLastSermon - itemsPerPage;
  const currentSermons = sermons.slice(indexOfFirstSermon, indexOfLastSermon);
  const totalPages = Math.ceil(sermons.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="pt-24 bg-gray-100 min-h-screen flex flex-col">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="sermon1.jpg" // Replace with your hero image path
          alt="Hero"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Sermons
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 sm:px-8 mt-8 flex-grow pb-16">
        {/* Added padding-bottom to ensure space for the footer */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
          {currentSermons.length > 0 ? (
            currentSermons.map((sermon) => (
              <div
                key={sermon.id}
                className="bg-white rounded-lg shadow-md overflow-hidden"
              >
                {sermon.image && (
                  <img
                    className="w-full h-48 object-cover"
                    src={`${sermon.image}`}
                    alt={sermon.title}
                  />
                )}
                <div className="p-4">
                  <div className="flex items-center mb-2">
                    <div className="bg-gray-800 flex items-center justify-center text-white text-sm"></div>
                    <div className="ml-2 flex-grow">
                      <h3 className="text-lg font-bold text-gray-800 mb-1">
                        {sermon.title}
                      </h3>
                      <p className="text-gray-600 text-sm mb-1">
                        {new Date(sermon.date).toLocaleString()}
                      </p>
                      <p className="text-gray-700 text-sm">
                        {sermon.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-800 text-center col-span-full">
              No sermons available
            </p>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="mt-8 flex justify-center">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="bg-teal-900 text-white p-2 rounded mr-2"
          >
            Previous
          </button>
          <span className="text-teal-900 p-2">
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-teal-900 text-white p-2 rounded"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

