import React, { useEffect, useState } from 'react';

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  useEffect(() => {
    // Fetch the gallery items from the backend
    fetch('https://churchserver.onrender.com/gallery')
      .then(response => response.json())
      .then(data => setGalleryItems(data))
      .catch(error => console.error('Error fetching gallery items:', error));
  }, []);

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = galleryItems.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(galleryItems.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="gallery.jpg"
          alt="Gallery"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Gallery
            </h1>
          </div>
        </div>
      </div>

      {/* Gallery Grid */}
      <div className="container mx-auto py-16 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {currentItems.map((item) => (
            <div
              key={item.id}
              className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer"
            >
              <img
                className="object-cover w-full h-64 md:h-80 transition-transform duration-300 ease-in-out group-hover:scale-110"
                src={item.image}
                alt={item.description}
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 text-white rounded-lg transition-opacity duration-300 ease-in-out flex items-end">
                <p className="text-left px-4 py-4 transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-8">
          <nav>
            <ul className="inline-flex items-center -space-x-px">
              {pageNumbers.map(number => (
                <li key={number}>
                  <button
                    onClick={() => paginate(number)}
                    className={`px-3 py-1 rounded-md transition-colors duration-300 ${
                      currentPage === number
                        ? 'bg-teal-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    }`}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Gallery;