import React from "react";

const Giving = () => {
  return (
    <div className="pt-24 pb-24 bg-gray-100 min-h-screen flex flex-col">
      {/* Hero Section */}
      <div className="relative mb-8">
        <div className="w-full h-48 md:h-64 lg:h-80 overflow-hidden">
          <img
            src="./give.jpg"
            alt="Hero"
            className="w-full h-full object-cover bg-center"
          />
        </div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-end text-white text-right pr-4 md:pr-8"></div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto flex flex-col md:flex-row mb-8">
        {/* Left Section */}
        <div className="w-full md:w-1/2 bg-white text-black px-8 py-12 mb-8 md:mb-0">
          <h1 className="text-4xl text-teal-800 font-bold mb-4">Giving</h1>
          <p className="text-lg text-gray-800 mb-8">
            "Each of you should give what you have decided in your heart to
            give, not reluctantly or under compulsion, for God loves a cheerful
            giver." - 2 Corinthians 9:7
          </p>
          <p className="text-lg text-gray-800 mb-8">
            Dear worshippers, giving is an act of worship and a way to honor God
            with our resources. Let us give cheerfully and generously, knowing
            that our contributions support the work of the Lord and bless those
            in need.
          </p>
          <p className="text-lg text-gray-800 mb-8">
            Our church is committed to being accountable and keeping accurate
            records of all givings and offerings. We value transparency and
            ensure that your contributions are handled with integrity and used
            for the intended purposes.
          </p>
          <div className="bg-teal-50 border-l-4 border-teal-600 p-4 rounded-md shadow-lg">
            <h2 className="text-2xl text-teal-800 font-semibold mb-2">
              Account Information
            </h2>
            <p className="text-lg text-gray-700">
              <span className="font-bold text-teal-800">Lipa Na Mpesa</span>
              <br />
              <span className="font-bold text-teal-800">Business Number:</span>{" "}
              400222
            </p>
            <p className="text-lg text-gray-700 mb-4">
              <span className="font-bold text-teal-800">Account Number:</span>{" "}
              1120113# (Offerings & Tithe)
            </p>
            <p className="text-sm text-gray-600 italic">
              Your generosity helps us continue our mission. Thank you for your
              support!
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full md:w-1/2 bg-gray-100 px-8 py-12">
          <h2 className="text-2xl font-bold mb-4 text-teal-900">
            Giving Options
          </h2>
          <p className="text-lg mb-4">
            Dear worshippers, you can pay your offerings and tithes using the
            following options:
          </p>
          <div className="mb-6">
            <h3 className="text-lg font-semibold text-teal-900">Cash</h3>
            <p className="text-gray-600">
              You can give your cash offerings and tithes during the service.
            </p>
          </div>
          <div className="mb-6">
            <h3 className="text-lg font-semibold text-teal-900">
              Development Fund Account
            </h3>
            <p className="text-gray-600">
              You can also pay using KCB with the following details:
            </p>
            <div className="mt-2 bg-teal-900 text-white p-4 rounded-md">
              <p className="mb-2">
                Paybill: <span className="font-semibold">522522</span>
              </p>
              <p>
                Account Number:{" "}
                <span className="font-semibold">1210572486</span>
              </p>
            </div>
          </div>

          <p className="text-lg mb-8">
            In addition to financial contributions, we welcome and encourage our
            members to give their time and talents to support our church
            community. Some ways you can contribute include:
          </p>
          <ul className="list-disc list-inside text-gray-800 mb-4">
            <li className="mb-2">
              Volunteering for church events and activities
            </li>
            <li className="mb-2">
              Utilizing your skills and expertise to support church initiatives
            </li>
            <li className="mb-2">
              Participating in outreach programs and community service projects
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Giving;



