import { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faBars,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showAboutSubmenu, setShowAboutSubmenu] = useState(false);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setShowAboutSubmenu(false);
  };

  const handleAboutClick = () => {
    setShowAboutSubmenu(!showAboutSubmenu);
  };

  return (
    <nav className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg text-black fixed w-full top-0 z-10 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24">
          <div
            className={`flex items-center ${isOpen ? "justify-center flex-1" : "justify-start"} transition-all duration-300 ease-in-out`}
          >
            <NavLink
              to="/"
              className={`flex items-center text-teal-800 font-bold transition-all duration-300 ease-in-out ${isOpen ? "text-2xl" : "text-4xl"}`}
            >
              <img
                src="logo1.png"
                alt="Logo"
                className="h-24 w-24 sm:h-28 sm:w-28 lg:h-32 lg:w-32 rounded-full mr-2"
              />
              <div>
                <span
                  className={`${isOpen ? "text-lg" : "text-xl"} transition-all duration-300 ease-in-out`}
                >
                  Resurrected Christ
                </span>
                <span
                  className={`text-sm block transition-all duration-300 ease-in-out`}
                >
                  Celebration Centre
                </span>
              </div>
            </NavLink>
          </div>

          <div className="hidden md:flex">
            <div className="ml-10 flex items-baseline space-x-4">
              {/* Desktop menu links */}
              <NavLink
                to="/"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                About
              </NavLink>
              <NavLink
                to="/events"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                Events
              </NavLink>
              <NavLink
                to="/sermons"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                Sermons
              </NavLink>
              <NavLink
                to="/bible"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                Bible
              </NavLink>
              <NavLink
                to="/giving"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                Giving
              </NavLink>
              <NavLink
                to="/connect"
                className="text-teal-800 hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent px-3 py-2 rounded-md text-base font-medium"
                activeClassName="bg-gray-200"
              >
                Connect
              </NavLink>
            </div>
          </div>

          <div className="flex md:hidden">
            <button
              onClick={handleMenu}
              type="button"
              className="bg-gray-200 bg-opacity-30 backdrop-filter backdrop-blur-lg inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-black"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="block h-4 w-4"
                  aria-hidden="true"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faBars}
                  className="block h-4 w-4"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`md:hidden transition-all duration-500 ease-in-out overflow-hidden ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}
        style={{
          transitionProperty: "max-height, opacity",
          transitionTimingFunction: isOpen
            ? "cubic-bezier(0.4, 0, 0.2, 1)"
            : "cubic-bezier(0.4, 0, 0.2, 1)",
        }}
      >
        <div
          className={`px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg transform transition-all duration-500 ease-in-out ${isOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"}`}
        >
          {/* Mobile menu links */}
          {!showAboutSubmenu ? (
            <>
              <NavLink
                to="/"
                className="text-black hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-900"
                onClick={closeMenu}
              >
                Home
              </NavLink>
              <div className="flex items-center justify-between">
                <NavLink
                  to="/about"
                  className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                  activeClassName="bg-gray-200"
                  onClick={handleAboutClick}
                >
                  About
                </NavLink>
                <FontAwesomeIcon
                  icon={showAboutSubmenu ? faChevronUp : faChevronDown}
                  className="text-black h-5 w-5"
                />
              </div>
              <NavLink
                to="/events"
                className="text-black hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Events
              </NavLink>
              <NavLink
                to="/sermons"
                className="text-black hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Sermons
              </NavLink>
              <NavLink
                to="/bible"
                className="text-black hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Bible
              </NavLink>
              <NavLink
                to="/giving"
                className="text-black hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Giving
              </NavLink>
              <NavLink
                to="/connect"
                className="text-black hover:text-transparent bg-clip-text bg-gradient-to-r from-teal-600 via-teal-600 to-transparent block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Connect
              </NavLink>
            </>
          ) : (
            <>
              <button
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                onClick={() => setShowAboutSubmenu(false)}
              >
                Back
              </button>
              <NavLink
                to="/statement-of-faith"
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Statement of Faith
              </NavLink>
              <NavLink
                to="/mission-vision-values"
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Mission, Vision, Values
              </NavLink>
              <NavLink
                to="/leadership"
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Leadership
              </NavLink>
              <NavLink
                to="/faq"
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                FAQ
              </NavLink>
              <NavLink
                to="/gallery"
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Gallery
              </NavLink>
              <NavLink
                to="/strategic-plan"
                className="text-black hover:text-teal-600 block px-3 py-2 rounded-md text-xl font-medium"
                activeClassName="bg-gray-200"
                onClick={closeMenu}
              >
                Strategic Plan
              </NavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
