import React from 'react';

const FAQ = () => {
  return (
    <div className="pt-24 bg-gray-100 min-h-screen">
      {/* Hero Image */}
      <div className="relative h-64 md:h-96 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="faq.jpg"
          alt="Frequently Asked Questions"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
          <div className="text-center px-4">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold">
              Frequently Asked Questions
            </h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-8 px-4">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-teal-900 mb-4">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-semibold">What time are the Sunday Services?</h3>
              <p className="text-gray-600">
                Our Sunday Services are as follows:
                <ul className="list-disc list-inside">
                  <li>6:00AM - 7:00AM - Morning Devotion Service</li>
                  <li>8:00AM - 9:00AM - 2nd Morning Devotion Service</li>
                  <li>9:00AM - 9:40AM - Mentorship Teaching's</li>
                  <li>9:40AM - 10:30AM - Bible Study</li>
                  <li>10:30AM - 1:00PM - Main Service</li>
                </ul>
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold">Where is the church located?</h3>
              <p className="text-gray-600">
                Our church is located at Ruiru, Wataalam Road, Moon Hunters next to Wamaria cereals.
                Google Maps Pin Location: (-1.150683, 36.946336).
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold">How can I get involved with church activities?</h3>
              <p className="text-gray-600">
                You can get involved by joining our various ministries and attending our weekday services:
                <ul className="list-disc list-inside">
                  <li>Tuesday: 6:30PM - 7:30PM - Women Fellowship</li>
                  <li>Wednesday: 6:30PM - 7:30PM - Cell Group Fellowship</li>
                  <li>Thursday: 6:30PM - 7:30PM - Men Fellowship</li>
                  <li>Friday: 6:30PM - 7:30PM - Week Service</li>
                </ul>
              </p>
            </div>
            <div>
            <h3 class="text-xl font-semibold">How Can I Contact or Connect with the Pastor?</h3>
<p class="text-gray-600">
  To contact or connect with the pastor, please visit the "Connect" section on our website. You can also reach out via email or phone, details of which are provided on the same page.
</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold">What should I expect when I join the church?</h3>
              <p className="text-gray-600">
                When you join our church, you will experience love, community, and faith together. You will be welcomed into a supportive and uplifting community committed to spiritual growth and meaningful connections.
              </p>
            </div>
            {/* Add more FAQs as needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;