import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
      <footer className="bg-teal-900 py-12 text-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Section 1: Church Name and About */}
          <div>
            <h2 className="text-xl font-bold mb-4">Ressurected Christ Celebration Centre</h2>
            <p className="text-sm">Celebrating the joy of Christ's teachings</p>
          </div>
          {/* Section 2: Quick Links */}
          <div>
            <h2 className="text-xl font-bold mb-4">Quick Links</h2>
            <ul className="list-none">
              <li>
                <Link to="/" className="hover:text-teal-300">Home</Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-teal-300">About</Link>
              </li>
              <li>
                <Link to="/signup" className="hover:text-teal-300">Events</Link>
              </li>
             
            </ul>
          </div>
          {/* Section 3: Contact Us */}
          <div>
            <h2 className="text-xl font-bold mb-4">Contact Us</h2>
            <p className="text-sm">
              Moon Hunters, Ruiru<br />
              Email: rcccruiru@gmail.com<br />
              Phone: +254 722849027
            </p>
          </div>
          {/* Section 4: Social Media Links */}
          <div>
            <h2 className="text-xl font-bold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              {/* Replace "#" with your actual social media links */}
              <a href="https://twitter.com/home" className="hover:text-teal-300">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/home" className="hover:text-teal-300">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://twitter.com/home" className="hover:text-teal-300">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://twitter.com/home" className="hover:text-teal-300">
              <i className="fab fa-whatsapp"></i>
              </a>
            </div>
            <div className="container mx-auto text-white text-center">
              <p className="text-sm">
                {/* Add your footer content here */}
                © 2024 Ressurected Christ Celebration Center. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;